import * as React from "react"
import { graphql } from "gatsby"
import { useState } from "react"
import { Helmet } from "react-helmet"
import { useContext } from "react"

import {
  Header,
  Footer,
  BottomNavbar,
  Blog,
  NoAuth,
  NoAccess,
  Loader,
} from "../components/index"

import mockup from "../utils/data"

// Context
import UserContext from "../context/UserAuth"

const IndexPage = ({ data }) => {
  const { isAuthenticated, isAccess, isLoading } = useContext(UserContext) || {
    isAuthenticated: false,
    isAccess: false,
    isLoading: false,
  }

  const posts = data.posts.edges

  return (
    <div>
      <Helmet>
        <title>Esteekey - Blog</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)]">
        {isLoading && <Loader />}
        {!isAuthenticated ? (
          <NoAuth />
        ) : !isAccess ? (
          <NoAccess />
        ) : (
          <Blog mockup={posts} category={data.blogTags.nodes} />
        )}
      </div>
      <BottomNavbar />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query HomePageQuery {
    blogTags: allSanityPostCategory {
      nodes {
        title
        titleRU
      }
    }
    posts: allSanityPost {
      edges {
        node {
          id
          _createdAt(formatString: "hh:mm A - DD/MM//YYYY")
          author {
            name
          }
          slug {
            current
          }
          mainImage {
            asset {
              localFile(width: 800) {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    quality: 90
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          category {
            title
            titleRU
          }
          title
          titleRU
          publishedAt(formatString: "HH:MM A - DD/MM/YYYY")
          preview
        }
      }
    }
  }
`

export default IndexPage
